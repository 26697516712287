import React from "react";
import "./css/index.css";
import { useRef } from "react";
import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ArrowSvg } from "../../svg/arrow.svg";
import useArrowIn from "../../lib/useArrowIn";
function Work() {
  const [navIndex, setNavIndex] = useState(0);
  const Section1Arrow = useRef(null);
  const location = useLocation();

  useArrowIn(0.2, Section1Arrow, 291);

  useEffect(() => {
    if (location.state) {
      setNavIndex(location.state);
    }

    return () => {};
  }, [location]);
  console.log(location.state);

  return (
    <main className="work">
      {navIndex === 0 ? (
        <>
          <div className="main-section">
            <div className="background">
              <div className="background-color">
                <div className="wrapper-menu">
                  <div className="menu">
                    <ul className="menu-bar">
                      {menu.map((item, idx) => {
                        return (
                          <li
                            className={`${
                              navIndex === idx ? "nav-active" : "nav-none"
                            }`}
                            key={idx}
                            onClick={() => {
                              setNavIndex(idx);
                            }}
                          >
                            {item}
                          </li>
                        );
                      })}
                      <div
                        className="menu-active-bar"
                        style={{ left: `${navIndex * 90}px` }}
                      />
                    </ul>
                  </div>
                </div>
                <div className="main-content">
                  <div className="title font-subtitle3">사업분야</div>
                  <div className="sub font-subtitle2">
                    자동화되어 가속화되는 환경개선
                  </div>
                  <div className="text font-head1">그린 디바이스</div>
                  <div className="arrow-wrapper">
                    <div className="line" ref={Section1Arrow}>
                      <ArrowSvg />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="wrapper">
              <div className="title">
                기기의 설계와 연구를 통해 <span> 자동화된 방식으로</span> <br />
                그렇기 때문에 <span>지속가능한 방식</span>으로 <br /> 환경을
                개선할 수 있는 <span>습관</span>을 만듭니다
              </div>

              <div className="content">
                {box.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="box">
                        <div className="box-wrapper">
                          <div className="title">{item.title}</div>
                          <img src={item.img} alt="arrow" />
                          <div className="text font-head2">{item.text}</div>
                          <div className="sub font-subtitle1">{item.sub}</div>
                        </div>
                      </div>
                      <div className="background">
                        <img
                          src="assets/work/work1-1.png"
                          srcSet="assets/work/work1-1@2x.png 2x, assets/work/work1-1@3x.png 3x"
                          alt="backround"
                        />
                      </div>
                      <div className="background-second">
                        <img
                          src="assets/work/work1-2.png"
                          srcSet="assets/work/work1-2@2x.png 2x, assets/work/work1-2@3x.png 3x"
                          alt="backround"
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : navIndex === 1 ? (
        <>
          <div className="main-section-second">
            <div className="background-second">
              <div className="background-color">
                <div className="wrapper-menu">
                  <div className="menu">
                    <ul className="menu-bar">
                      {menu.map((item, idx) => {
                        return (
                          <li
                            className={`${
                              navIndex === idx ? "nav-active" : "nav-none"
                            }`}
                            key={idx}
                            onClick={() => {
                              setNavIndex(idx);
                            }}
                          >
                            {item}
                          </li>
                        );
                      })}
                      <div
                        className="menu-active-bar"
                        style={{ left: `${navIndex * 90}px` }}
                      />
                    </ul>
                  </div>
                </div>
                <div className="main-content">
                  <div className="title font-subtitle3">사업분야</div>
                  <div className="sub font-subtitle2">
                    일상의 편리함을 지키는 미래 대비법
                  </div>
                  <div className="text font-head1">에코 대체제</div>
                  <div className="arrow-wrapper">
                    <div className="line" ref={Section1Arrow}>
                      <ArrowSvg />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section1-2">
            <div className="wrapper">
              <div className="title">
                기존 자원을 대체하는 <span> 환경 대체제</span>의 개발로
                <br />
                <span>일상의 편리</span>함을 지키며 <br />
                동시에<span>환경개선의 양립</span>을 추구합니다.
              </div>

              <div className="content">
                {boxSecond.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="box">
                        <div className="box-wrapper">
                          <div className="title">{item.title}</div>
                          <img src={item.img} alt="arrow" />

                          <div className="subTitle font-body1">
                            {item.subTitle}
                          </div>
                          <div className="text font-head2">{item.text}</div>
                          <div className="sub font-subtitle1">{item.sub}</div>
                        </div>
                      </div>
                      <div className="background">
                        <img
                          src="assets/work/work2-1.png"
                          srcSet="assets/work/work2-1@2x.png 2x, assets/work/work2-1@3x.png 3x"
                          alt="backround"
                        />
                      </div>
                      <div className="background-second">
                        <img
                          src="assets/work/work2-2.png"
                          srcSet="assets/work/work2-2@2x.png 2x, assets/work/work2-2@3x.png 3x"
                          alt="backround"
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : navIndex === 2 ? (
        <>
          <div className="main-section-third">
            <div className="background-third">
              <div className="background-color">
                <div className="wrapper-menu">
                  <div className="menu">
                    <ul className="menu-bar">
                      {menu.map((item, idx) => {
                        return (
                          <li
                            className={`${
                              navIndex === idx ? "nav-active" : "nav-none"
                            }`}
                            key={idx}
                            onClick={() => {
                              setNavIndex(idx);
                            }}
                          >
                            {item}
                          </li>
                        );
                      })}
                      <div
                        className="menu-active-bar"
                        style={{ left: `${navIndex * 90}px` }}
                      />
                    </ul>
                  </div>
                </div>
                <div className="main-content">
                  <div className="title font-subtitle3">사업분야</div>
                  <div className="sub font-subtitle2">
                    경험과 행동에서 환경을 지킬 수 있는 해답
                  </div>
                  <div className="text font-head1">경험 설계</div>
                  <div className="arrow-wrapper">
                    <div className="line" ref={Section1Arrow}>
                      <ArrowSvg />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1-3">
            <div className="wrapper">
              <div className="title">
                기술과 자재의 개발에서 나아가
                <br />
                <span>사람들의 경험과 행동</span>에서 <br />
                <span>환경을 지킬 수 있는 해답</span>을 찾습니다
              </div>

              <div className="line"></div>
              <div className="box-title">
                <div className="title font-subtitle3">써모아키텍쳐</div>
                <div className="sub font-head1">
                  철거없는 건물의 재개발 <br />
                  <span>써모웍스 친환경 단열재</span>
                </div>
                <div className="text font-body1">
                  건물 내부 및 천정에 진공단열재 시공시 열교 및 단열성능
                  극대화를 위한 <br />
                  시공 부자재 및 방법을 개발하여 건축물의 에너지 절감에 큰
                  역할을 하고있습니다
                </div>
              </div>

              <div className="content">
                {boxThird.map((item, index) => {
                  return (
                    <div key={index} className="box">
                      <div className="title font-title2">{item.title}</div>
                      <div className="text font-body2">{item.text}</div>
                    </div>
                  );
                })}
                <img
                  className="first"
                  src="assets/work/work3-1.png"
                  alt="img"
                  srcSet="assets/work/work3-1@2x.png 2x, assets/work/work3-1@3x.png 3x"
                />
                <img
                  className="second"
                  src="assets/work/work3-2.png"
                  alt="img"
                  srcSet="assets/work/work3-2@2x.png 2x, assets/work/work3-2@3x.png 3x"
                />
                <img
                  className="third"
                  src="assets/work/work3-3.png"
                  alt="img"
                  srcSet="assets/work/work3-3@2x.png 2x, assets/work/work3-3@3x.png 3x"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ""()
      )}
    </main>
  );
}

export default Work;

const menu = ["그린디바이스", "에코대체제", "경험설계"];

const box = [
  {
    title: (
      <>
        1000컵 기준 <br />
        <span className="font-subtitle1"> 1,000만원의 환경비용 절약 </span>
      </>
    ),

    img: "assets/work/work-arrow.svg",
    text: "캔웍스 캔시머",
    sub: "CAN SEAMER",
  },
  {
    title: (
      <>
        환경을 생각하는
        <br />
        <span className="font-subtitle1"> 콜드체인 풀필먼트 </span>
      </>
    ),

    img: "assets/work/work-arrow.svg",
    text: "콜드체인 풀필먼트",
    sub: "COLD CHAIN FULFILLMENT",
  },
];

const boxSecond = [
  {
    title: (
      <>
        1년에 소비되는 스티로폼, <br />
        <span className="font-subtitle1">
          {" "}
          그 자리를 대체하는 재활용 가능한 대체제
        </span>
      </>
    ),

    subTitle: "단열보온보냉 가방,의약품,식품",

    img: "assets/work/work-arrow.svg",
    text: "써모큐브",
    sub: "THERMO CUBE",
  },
  {
    title: (
      <>
        환경을 생각하는 패키지
        <br />
        <span className="font-subtitle1"> 단열 완충 단열충진재 </span>
      </>
    ),
    subTitle: "단열보온보냉 가방,의약품,식품",

    img: "assets/work/work-arrow.svg",
    text: "써모패키지",
    sub: "THERMO PACKAGE",
  },
];

const boxThird = [
  {
    title: (
      <>
        외단열 시스템의 열교차단형 <br />
        단열프레임 설계 및 개발
      </>
    ),

    text: (
      <>
        기존 외단열 공법시 외장재를 고정하는 구조프레임(스틸)의 열교로 인해
        에너지 손실이 발생하였음. 이를 해결하기위한 단열 프레임을 개발에
        성공하였으며, 단열성능 개선에 큰 역할을 함.
      </>
    ),
  },
  {
    title: (
      <>
        사전 제작형 프레임으로 <br />
        간편하고 깨끗한 현장 설치
      </>
    ),

    text: (
      <>
        신축, 노후건물 리모델링 공사시 현장에서 제작,시공하는 방식이 아닌,
        공장에서 부재와 단열, 마감재료를 일체형으로 생산하여 건축현장에서 간편히
        시공할수 있는 모듈 시스템을 한국건설기술연구원과 공동기술 개발하여
        건축물에 실증 적용 중이며, 1차 실증현장인 신현동 행정복지센터 의 경우
        여름철 냉방에너지 52% 절감효과에 큰 기여를 함.
      </>
    ),
  },
  {
    title: (
      <>
        다양한 마감재 적용가능한 <br />
        시스템으로 설계 및 개발
      </>
    ),

    text: (
      <>
        모듈 생산시 유리, 시트판넬, 세라믹 등 다양한 마감재료 적용이 가능한
        구조로 설계 개발완료 되어 단열성는 뿐만아니라 외관의 미려함에 큰 역할을
        하며, 태양광 모듈조립을 통해 건물 일체형태양광(BIPV)로 활용이 가능함으로
        기존 건축물의 에너지 향상에 큰 역할을 기대함.
      </>
    ),
  },
];
