import React from "react";
import "./css/index.css";
import { useRef } from "react";
import useDrawLine from "../../lib/useDrawLine";
import { ReactComponent as LineSvgPc } from "../../svg/about-line.svg";
import { ReactComponent as LineSvgTablet } from "../../svg/about-line-tablet.svg";
import { ReactComponent as LineSvg } from "../../svg/section1.svg";
import useScrollFadeIn from "../../lib/useScrollFadeIn";
import useDraw from "../../lib/useDraw";
function About({ UserState }) {
  const section = useRef(null);
  const sectiontablet = useRef(null);
  const section3tablet = useRef(null);
  useDrawLine(0.2, section);
  useDrawLine(0.2, sectiontablet);
  useScrollFadeIn(0.2, section3tablet);
  const section3 = useRef(null);
  useDraw(0.2, section3);
  return (
    <main className="about">
      <div className="main-section">
        <div className="background">
          <div className="background-color">
            <div className="wrapper">
              <LineSvgPc ref={section} className="pc" />

              <LineSvgTablet ref={sectiontablet} className="tablet" />
              <div className="text">
                친환경이 아닌, <br />
                필환경을 위한 끊임없는 도전 <br />
                써모웍스
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section1">
        <div className="wrapper">
          <div className="header">
            <div className="title font-subtitle3">HISTORY</div>
            <div className="sub">써모웍스가 걸어온 길</div>
          </div>
          <div className="awards">
            <img
              src="assets/about/section1-line.svg"
              alt="line"
              className="pc"
            />
            <img
              src="assets/about/tablet-section1-line.svg"
              alt="line"
              className="tablet"
            />
          </div>
          <div className="award-text2019">
            <div className="year font-subtitle1">2019</div>
            <div className="text font-body1">
              2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상 <br /> 2017 대한민국
              업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
            </div>
          </div>
          <div className="award-text2020">
            <div className="year font-subtitle1">2020</div>
            <div className="text font-body1">
              2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상 <br /> 2017 대한민국
              업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
            </div>
          </div>
          <div className="award-text2021">
            <div className="year font-subtitle1">2021</div>
            <div className="text font-body1">
              2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상 <br /> 2017 대한민국
              업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상
              <br /> 2017 대한민국 업사이클링 대전 장관상 수상 <br />
              2017 대한민국 업사이클링 대전 장관상 수상
            </div>
          </div>

          <div className="content">
            <div className="awards-title font-head2">인증 및 수상내역</div>
            <div className="awards-img">
              {award.map((item, index) => {
                return (
                  <div key={index} className="box">
                    <img src={item.img} srcSet={item.set} alt="award" />
                    <div className="title font-detail1">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="content-tablet">
            <div className="awards-title font-head2">인증 및 수상내역</div>
            <div className="awards-img">
              {awardTablet.map((item, index) => {
                return (
                  <div key={index} className="box">
                    <img src={item.img} srcSet={item.set} alt="award" />
                    <div className="title font-detail1">{item.title}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="wrapper">
          <div className="header">
            <div className="title font-subtitle3">VISION</div>
            <div className="sub font-head1">써모웍스가 걸어갈 길</div>
          </div>
          <div className="line">
            <img src="assets/about/section2-line.svg" alt="line" />
          </div>
          <div className="line-tablet">
            <img src="assets/about/tablet-section2-line.svg" alt="line" />
          </div>

          <div className="content">
            <div className="content-top">
              <div className="left">
                <div className="title font-subtitle1">
                  2023 주요 택배유통과정의 콜드체인
                </div>
                <div className="percent font-head2">
                  <span>20%</span>개선
                </div>
              </div>
              <div className="right">
                <div className="title font-body2">추정 사회적 가치</div>
                <div className="pay">
                  <span className="font-head2">102</span>억
                </div>
              </div>
            </div>

            <div className="content-middle">
              <div className="left">
                <div className="title font-subtitle1">
                  2024 수출 및 바이오산업의 콜드체인
                </div>
                <div className="percent font-head2">
                  <span>35%</span>개선
                </div>
              </div>
              <div className="right">
                <div className="title font-body2">기업 환원 이익</div>
                <div className="pay">
                  <span className="font-head2">300</span>억
                </div>
              </div>
            </div>
            <div className="content-bottom">
              <div className="left">
                <div className="title font-subtitle1">
                  2024 친환경 써모업그레이드 빌딩 전국
                </div>
                <div className="percent font-head2">
                  <span>35</span>곳 목표
                </div>
              </div>
              <div className="right">
                <div className="title font-body2">추정 절약 가치</div>
                <div className="pay">
                  <span className="font-head2">100</span>억
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {UserState === "pc" ? (
        <div className="section3">
          <div className="section1-wrapper">
            <div className="content ">
              <div className="title">
                써모웍스는 <br />
                <span className="font-head2">친환경만을 목표하지 않습니다</span>
              </div>
              <LineSvg ref={section3} />
              <div className="right-img">
                <img
                  src="/assets/main/section1-1.png"
                  srcSet="assets/main/section1-1@2x.png 2x, assets/main/section1-1@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="left-img">
                <img
                  src="/assets/main/section1-2.png"
                  srcSet="assets/main/section1-2@2x.png 2x, assets/main/section1-2@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="sub font-title1">
                환경을 움직이는, 동력 그 자체가 되겠습니다
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section3-tablet">
          <div className="section1-wrapper " ref={section3tablet}>
            <div className="content">
              <div className="title">
                써모웍스는 <br />
                <span className="font-title2">
                  친환경만을 목표하지 않습니다
                </span>
              </div>
              <div className="right-img">
                <img
                  src="/assets/about/tablet-img-1.png"
                  srcSet="assets/about/tablet-img-1@2x.png 2x, assets/about/tablet-img-1@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="line"></div>
              <div className="left-img">
                <img
                  src="/assets/about/tablet-img-2.png"
                  srcSet="assets/about/tablet-img-2@2x.png 2x, assets/about/tablet-img-2@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="line-bottom"></div>
              <div className="sub font-title2">
                환경을 움직이는, 동력
                <br />그 자체가 되겠습니다
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default About;

const award = [
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
];

const awardTablet = [
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
  {
    img: "assets/about/award-1.png",
    set: "assets/about/award-1@2x.png 2x, assets/about/award-1@3x 3x",
    title: (
      <>
        2017 대한민국 업사이클링 대전 <br />
        장관상 수상{" "}
      </>
    ),
  },
];
