import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main/MainPage";
import Navbar from "./components/Navbar/Navbar";
import "./core/core.css";
import Footer from "./components/footer/Footer";
import About from "./pages/About/About";
import Work from "./pages/Work/Work";
import ContactUs from "./pages/ContactUS/ContactUs";
import ScrollTop from "./components/ScrollTop/ScrollTop";
import { useEffect, useState } from "react";

function App() {
  const [UserState, setUserState] = useState("pc");
  useEffect(() => {
    function ResizeEvent(e) {
      const Width = e.target.innerWidth;
      if (Width > 1439) {
        setUserState("pc");
      }
      if (1440 > Width && Width > 767) {
        setUserState("tablet");
      }
      if (Width < 768) {
        setUserState("mobile");
      }
    }
    window.addEventListener("resize", ResizeEvent);

    return () => {
      window.removeEventListener("resize", ResizeEvent);
    };
  }, []);
  useEffect(() => {
    const Width = window.innerWidth;
    if (Width > 1439) {
      setUserState("pc");
    }
    if (1440 > Width && Width > 767) {
      setUserState("tablet");
    }
    if (Width < 768) {
      setUserState("mobile");
    }
    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <Navbar UserState={UserState} />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Main UserState={UserState} />} />
        <Route path="/about" element={<About UserState={UserState} />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<ContactUs UserState={UserState} />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
