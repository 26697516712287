import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useCallback, useState } from "react";
import useScrollFadeIn from "../../lib/useScrollFadeIn";

function MainSection5({ UserState }) {
  const section5one = useRef(null);
  const section5two = useRef(null);
  const section5three = useRef(null);
  const section5four = useRef(null);
  const section5five = useRef(null);
  const section5six = useRef(null);
  const section5seven = useRef(null);
  const section5eight = useRef(null);
  const section5nine = useRef(null);

  useScrollFadeIn(0.2, section5one);
  useScrollFadeIn(0.2, section5two);
  useScrollFadeIn(0.2, section5three);
  useScrollFadeIn(0.2, section5four);
  useScrollFadeIn(0.2, section5five);
  useScrollFadeIn(0.2, section5six);
  useScrollFadeIn(0.1, section5seven);
  useScrollFadeIn(0.3, section5eight);
  useScrollFadeIn(0.5, section5nine);
  return (
    <>
      <div className={"section5"}>
        <div className="section5-wrapper">
          <div className="content">
            <div className="section5-title">
              <div className="title font-subtitle3">브랜드목표</div>
              <div className="sub font-head1">
                써모웍스는 <br className="mobile" />
                불편을 감수하지 않고도 <br /> 우리의 미래를 준비하는{" "}
                <br className="mobile" />
                저력을 찾습니다
              </div>
            </div>

            <div className="section5-1-wrapper" ref={section5one}>
              <div className="section5-1-title font-title1">
                <div className="title-wrapper">
                  <div className="number">01</div>
                  <div className="text">그린 디바이스</div>
                </div>
              </div>

              <div className="section5-1">
                <div className="top-text">넘쳐나는 플라스틱 컵을</div>
                <div className="section5-left">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-top.svg" />
                    <div className="text">넘쳐나는 플라스틱 컵을</div>
                  </div>
                </div>
                <div className="section5-right">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-bottom.svg" />
                    <div className="text">재사용가능한 알루미늄캔으로</div>
                  </div>
                </div>
                <div className="top-text bottom-text">
                  재사용가능한 알루미늄캔으로
                </div>
              </div>
            </div>

            <div className="section5-1-wrapper" ref={section5two}>
              <div className="section5-1-title font-title1">
                <div className="title-wrapper">
                  <div className="number">02</div>
                  <div className="text">에코대체제</div>
                </div>
              </div>

              <div className="section5-1">
                <div className="top-text">넘쳐나는 플라스틱 컵을</div>
                <div className="section5-left section5-3-left">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-top.svg" />
                    <div className="text">넘쳐나는 플라스틱 컵을</div>
                  </div>
                </div>
                <div className="section5-right section5-4-right">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-bottom.svg" />
                    <div className="text">재사용가능한 알루미늄캔으로</div>
                  </div>
                </div>
                <div className="top-text bottom-text">
                  재사용가능한 알루미늄캔으로
                </div>
              </div>
            </div>

            <div className="section5-1-wrapper" ref={section5three}>
              <div className="section5-1-title font-title1">
                <div className="title-wrapper">
                  <div className="number">03</div>
                  <div className="text">경험설계</div>
                </div>
              </div>

              <div className="section5-1">
                <div className="top-text">우풍이 심한 오래된 건물을</div>
                <div className="section5-left section5-5-left">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-top.svg" />
                    <div className="text">넘쳐나는 플라스틱 컵을</div>
                  </div>
                </div>
                <div className="section5-right section5-6-right">
                  <div className="arrow-title">
                    <img src="/assets/main/arrow-bottom.svg" />
                    <div className="text">재사용가능한 알루미늄캔으로</div>
                  </div>
                </div>
                <div className="top-text bottom-text">
                  신소재 건축공법으로 따뜻한 새 건물로
                </div>
              </div>
            </div>
          </div>
          <div className="content-bottom">
            <div className="title" ref={section5seven}>
              감수하고,
            </div>
            <div className="title" ref={section5eight}>
              참고,
            </div>
            <div className="title" ref={section5nine}>
              기다리지 않아도,
            </div>
            <div className="line"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection5;
