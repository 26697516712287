import React from "react";
import { useState, useEffect } from "react";

function MainSection2() {
  const [now, setNow] = useState(0);

  useEffect(() => {
    const autoPage = setTimeout(() => {
      setTimeout(() => {
        setNow((prev) => (prev === 2 ? 0 : prev + 1));
      });
    }, 2000);
    return () => {
      clearTimeout(autoPage);
    };
  }, [now]);

  return (
    <div className="section2">
      <div className="section2-wrapper">
        <div className="content">
          <div className="left">
            <div className="title font-subtitle2">우리는 질문합니다</div>
            <div className="sub">친환경이 아니라</div>
            <div className="text font-head1">필환경이 될 수는 없을까?</div>
          </div>
          <div className="right">
            {section2.map((item, index) => {
              return (
                <div
                  key={index}
                  className="box"
                  style={{ transform: `translateY(-${now * 250}px)` }}
                >
                  <img src="assets/main/section2-left.svg" />
                  <div className="content font-title1">{item.title}</div>
                  <img src="assets/main/section2-right.svg" />
                </div>
              );
            })}
          </div>
          <div className="right-mobile">
            {section2.map((item, index) => {
              return (
                <div
                  key={index}
                  className="box"
                  style={{ transform: `translateY(-${now * 110}px)` }}
                >
                  <img src="assets/main/section2-left.svg" />
                  <div className="content font-title1">{item.title}</div>
                  <img src="assets/main/section2-right.svg" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainSection2;

const section2 = [
  {
    title: "     왜 수습만 해야하는지",
  },
  {
    title: (
      <>
        사람들의 의식이 <br />
        개선되기만 기다려야하는지{" "}
      </>
    ),
  },
  {
    title: (
      <>
        지금 당장 <br />
        개선될 수 있는 것은 없는지
      </>
    ),
  },
];
