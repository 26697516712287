import { useRef } from "react";
import MainSection2 from "../../components/MainSection2/MainSection2";
import { ReactComponent as Section6 } from "../../svg/section6-arrow.svg";
import { ReactComponent as ArrowSvg } from "../../svg/arrow.svg";
import { ReactComponent as LineSvg } from "../../svg/section1.svg";
import "./css/index.css";

import useScrollFadeIn from "../../lib/useScrollFadeIn";
import MainSection4 from "../../components/Mainsection4/MainSection4";
import MainSection5 from "../../components/Mainsection5/MainSection5";
import useArrowIn from "../../lib/useArrowIn";
import useDraw from "../../lib/useDraw";

function MainPage({ UserState }) {
  const section1 = useRef(null);
  const section6 = useRef(null);
  const section2one = useRef(null);
  const section2two = useRef(null);
  const section1tablet = useRef(null);
  const Section1Arrow = useRef(null);
  const Section3Arrow = useRef(null);
  useScrollFadeIn(0.2, section2one);
  useScrollFadeIn(0.2, section2two);
  useDraw(0.2, section1);
  useDraw(0.1, section6);
  useScrollFadeIn(0.1, section1tablet);
  useArrowIn(0.2, Section1Arrow, 291);
  useArrowIn(0.2, Section3Arrow, UserState === "pc" ? 488 : 291);

  return (
    <main className="main-page">
      <div className="main-section">
        <div className="main-backround">
          <div className="backround">
            <div className="left-backround"></div>
            <div className="right-backround"></div>
          </div>
          <div className="backround-color">
            <div className="main-wrapper">
              <div className="main-title">
                <div className="img">
                  <img src="/assets/main/main-logo.svg" alt="logo" />
                </div>
                <div className="title">
                  빈자리는 <span>채우고</span> <br />
                  넘치는 자리는 <span> 비워내는</span> <br />
                  <span> 열을 닮은 우리</span>{" "}
                </div>
                <div className="arrow-wrapper">
                  <div className="line" ref={Section1Arrow}>
                    <ArrowSvg />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {UserState === "pc" ? (
        <div className="section1">
          <div className="section1-wrapper">
            <div className="content ">
              <div className="title">
                써모웍스는 <br />
                <span className="font-head2">친환경만을 목표하지 않습니다</span>
              </div>
              <LineSvg ref={section1} />
              <div className="right-img">
                <img
                  src="/assets/main/section1-1.png"
                  srcSet="assets/main/section1-1@2x.png 2x, assets/main/section1-1@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="left-img">
                <img
                  src="/assets/main/section1-2.png"
                  srcSet="assets/main/section1-2@2x.png 2x, assets/main/section1-2@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="sub font-title1">
                환경을 움직이는, 동력 그 자체가 되겠습니다
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section1-tablet">
          <div className="section1-wrapper " ref={section1tablet}>
            <div className="content">
              <div className="title">
                써모웍스는 <br />
                <span className="font-title2">
                  친환경만을 목표하지 않습니다
                </span>
              </div>
              <div className="right-img">
                <img
                  src="/assets/about/tablet-img-1.png"
                  srcSet="assets/about/tablet-img-1@2x.png 2x, assets/about/tablet-img-1@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="line"></div>
              <div className="left-img">
                <img
                  src="/assets/about/tablet-img-2.png"
                  srcSet="assets/about/tablet-img-2@2x.png 2x, assets/about/tablet-img-2@3x.png 3x"
                  alt="line"
                />
              </div>
              <div className="line-bottom"></div>
              <div className="sub font-title2">
                환경을 움직이는, 동력
                <br />그 자체가 되겠습니다
              </div>
            </div>
          </div>
        </div>
      )}
      <MainSection2 />
      <div className="section3">
        <div className="section3-wrapper">
          <div className="content">
            <div className="title font-head2">이제 우리는 대답합니다</div>
            <div className="black-arrow-wrapper">
              <div className="black-line" ref={Section3Arrow}>
                <ArrowSvg />
              </div>
            </div>
            <div className="sub font-subtitle2" ref={section2one}>
              필환경은 개인의 개선에 의지하는 것이 아닌
            </div>
            <div className="text font-head1" ref={section2two}>
              기술의 개발에서 오는 것이라고
            </div>
            <div className="background-img">
              <div className="background"></div>
            </div>
          </div>
        </div>
      </div>
      <MainSection4 />
      <MainSection5 UserState={UserState} />

      <div className="section6">
        <div className="background"></div>
        <div className="background-color"></div>
        <div className="content">
          <div className="title font-head1">
            어쩌면 지금 일상을 <br className="br" />
            그대로 이어나가도 되는
          </div>
          <Section6 ref={section6} />
        </div>
      </div>

      <div className="section7">
        <div className="section7-wrapper">
          <div className="title font-head2">
            그런 지속가능한 <br className="br" />
            환경개선을 해나갑니다.
          </div>
          <img src="assets/common/logo-mid.svg" alt="logo" />
        </div>
      </div>
    </main>
  );
}

export default MainPage;
