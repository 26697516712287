import React, { useRef } from "react";
import useDraw from "../../lib/useDraw";
import "./css/index.css";
import { ReactComponent as PcLine } from "../../svg/contact-line.svg";
import { ReactComponent as TbLine } from "../../svg/tablet-line.svg";
import { ReactComponent as MbLine } from "../../svg/mobile-line.svg";
import "./css/index.css";
function ContactUs({ UserState }) {
  const LineRef = useRef(null);
  useDraw(0, LineRef);
  return (
    <main className="contact">
      <div className="wrapper">
        <div className="main-backround">
          <div className="backround"></div>
          <div className="backround-color">
            {UserState === "pc" ? (
              <div className="main-wrapper">
                <div className="main-title">
                  <div className="title font-type">CONTACT US</div>
                  <div className="sub font-title2">
                    써모웍스에 대해 <br />
                    궁금하신 점이 있나요?
                  </div>
                </div>
                <div className="main-sub font-subtitle2">
                  <div className="top">
                    <div className="call">CALL</div>
                    <div className="call-text">+82 70-3224-1234</div>
                  </div>
                  <div className="bottom">
                    <div className="email">EMAIL</div>
                    <div className="email-text">thermoworks@thermoworks.cs</div>
                  </div>
                </div>
                <div className="img">
                  <PcLine ref={LineRef} />
                </div>
                <div className="img-tablet">
                  <img src="/assets/contact/tablet-line.svg" alt="line" />
                </div>
              </div>
            ) : UserState === "tablet" ? (
              <div className="main-wrapper-tablet">
                <div className="main-title">
                  <div className="title font-type">CONTACT US</div>
                  <div className="sub font-title2">
                    써모웍스에 대해 궁금하신 점이 있나요?
                  </div>
                </div>
                <div className="main-sub font-subtitle2">
                  <div className="top">
                    <div className="call">CALL</div>
                    <div className="call-text">+82 70-3224-1234</div>
                  </div>
                  <div className="bottom">
                    <div className="email">EMAIL</div>
                    <div className="email-text">thermoworks@thermoworks.cs</div>
                  </div>
                </div>
                <div className="img-tablet">
                  <TbLine ref={LineRef} />
                </div>
              </div>
            ) : (
              <div className="main-wrapper-mobile">
                <div className="main-title">
                  <div className="title font-type">CONTACT US</div>
                  <div className="sub font-subtitle1">
                    써모웍스에 대해 궁금하신 점이 있나요?
                  </div>
                </div>
                <div className="main-sub font-subtitle4">
                  <div className="top">
                    <div className="call">CALL</div>
                    <div className="call-text">+82 70-3224-1234</div>
                  </div>
                  <div className="bottom">
                    <div className="email">EMAIL</div>
                    <div className="email-text">thermoworks@thermoworks.cs</div>
                  </div>
                </div>
                <div className="img-mobile">
                  <MbLine ref={LineRef} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ContactUs;
