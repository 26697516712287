import React from "react";
import { ReactComponent as LogoSvg } from "../../svg/logo.svg";
import { ReactComponent as MenuSvg } from "../../svg/menu.svg";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./css/index.css";

function Navbar({ UserState }) {
  const category = useLocation().pathname.split("/")[1];
  const [isOpen, setIsOpen] = useState(false);
  const [HeaderState, setHeaderState] = useState("");
  useEffect(() => {
    const root = document.getElementById("root");
    function ScrollEvent(e) {
      const top = e.target.scrollTop;
      if (top > 100) {
        setHeaderState("black");
      } else {
        setHeaderState("");
      }
    }
    root.addEventListener("scroll", ScrollEvent);

    return () => {
      root.removeEventListener("scroll", ScrollEvent);
    };
  }, [isOpen]);

  return (
    <>
      <header>
        <div
          className={`Navbar ${category}-header ${
            isOpen ? "open" : HeaderState
          }-header`}
        >
          <div className="wrapper">
            <Link
              className="logo-wrapper"
              to={"/"}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <LogoSvg />
            </Link>
            {UserState === "pc" ? (
              <div className="nav-wrapper ">
                {NavLayout.map(({ title, link }, idx) => {
                  return (
                    <Link key={idx} className="nav-btn" to={link}>
                      {title}
                    </Link>
                  );
                })}
              </div>
            ) : (
              <button
                className="menu-btn"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                {isOpen ? (
                  <img src="/assets/common/cancel.svg" alt="" />
                ) : (
                  <MenuSvg />
                )}
              </button>
            )}
          </div>
        </div>
      </header>
      {UserState !== "pc" ? (
        <div className={`open-menu-section ${isOpen ? "open-nav" : ""}`}>
          {NavLayout.map(({ title, link }, idx) => {
            return (
              <Link
                key={idx}
                className="nav-btn"
                to={link}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {title}
              </Link>
            );
          })}
        </div>
      ) : undefined}
    </>
  );
}

export default Navbar;

const NavLayout = [
  {
    title: "ABOUT",
    link: "/about",
  },
  {
    title: "WORKS",
    link: "/work",
  },
  {
    title: "CONTACT US",
    link: "/contact",
  },
];
