import React from "react";
import "./css/index.css";

function Footer() {
  return (
    <footer>
      {" "}
      <div className="footer">
        <div className="wrapper">
          <img src="assets/common/logo.svg" alt="logo" />

          <div className="footer-box">
            <div className="left">
              써모웍스 | 대표 홍길동 | 사업자등록번호 123-12-01234 <br />
              통신판매업등록번호 2022-2345-0282 <br />
              <div className="etc">약관 및 정책</div>
            </div>
            <div className="right">
              <div className="location text">
                <img src="assets/footer/icon-location.svg" alt="location" />
                대구광역시 동구 동대구로 465 대구스케일업허브
              </div>
              <div className="call text">
                <img src="assets/footer/icon-call.svg" alt="location" />
                0507-1453-5492
              </div>
              <div className="mail text">
                <img src="assets/footer/icon-mail.svg" alt="location" />
                abcd@naver.com
              </div>
            </div>
          </div>
          <div className="etc-m">약관 및 정책</div>

          <div className="text font-body3">
            Copyright Thermo works, All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
