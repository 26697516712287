import { useCallback, useEffect } from "react";

const useDraw = (delay, dom) => {
  const handleScroll = useCallback(
    ([entry]) => {
      const { current } = dom;
      if (entry.isIntersecting) {
        current.style.transitionProperty = "stroke-dashoffset";
        current.style.transitionDuration = "3s";
        current.style.transitionTimingFunction = "ease-out";
        current.style.transitionDelay = `${delay}s`;
        current.style.strokeDashoffset = `0`;
      }
    },
    [dom, delay]
  );

  useEffect(() => {
    if (dom) {
      let observer;
      const { current } = dom;
      if (current) {
        current.style.strokeDashoffset = 500;
        current.style.strokeDasharray = 500;
        observer = new IntersectionObserver(handleScroll, {
          threshold: 0.1,
        });
        observer.observe(current);

        return () => observer && observer.disconnect();
      }
    }
  }, [handleScroll, dom]);

  return {
    title: "return",
  };
};

export default useDraw;
