import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useCallback } from "react";
import useScrollFadeIn from "../../lib/useScrollFadeIn";

function MainSection4() {
  const navigate = useNavigate();
  const section4four = useRef(null);
  const section4five = useRef(null);
  const section4six = useRef(null);
  const section4one = useRef(null);
  const section4Second = useRef(null);
  const section4sthird = useRef(null);

  useScrollFadeIn(0.5, section4one);
  useScrollFadeIn(0.3, section4Second);
  useScrollFadeIn(0.1, section4sthird);
  useScrollFadeIn(0.2, section4four);
  useScrollFadeIn(0.2, section4five);
  useScrollFadeIn(0.2, section4six);

  const Navwork = useCallback(
    (type) => {
      console.log(type);
      navigate("/work", { state: type });
    },
    [navigate]
  );
  return (
    <>
      <div className="section4">
        <div className="section4-wrapper">
          <div className="content">
            <div className="content-wrapper">
              <div className="title font-subtitle3">사업분야</div>
              <div className="sub font-head1">
                필환경을 위한 첫걸음,
                <br /> 써모웍스
              </div>

              <div className="content-fix-wrapper">
                <div className="content-left" ref={section4one}>
                  <div className="background-color">
                    <div className="content-top">
                      <div className="title font-title2">그린디바이스</div>
                      <div className="sub font-body2">
                        기기의 설계와 연구를 통해 자동화된 방식으로
                        <br /> 그렇기 때문에 지속가능한 방식으로
                        <br /> 환경을 개선할 수 있는 습관을 만듭니다
                      </div>
                      <div
                        className="btn"
                        onClick={() => {
                          Navwork(0);
                        }}
                      >
                        <div className="title font-subtitle4">더보기</div>
                        <img src="assets/common/right-arrow.svg" alt="arrow" />
                      </div>
                      <div className="text font-subtitle2">
                        캔웍스 개발 <br />
                        콜드체인 배송을 위한 풀필먼트 포장재설비
                      </div>
                    </div>
                  </div>
                  <div className="background">
                    <img
                      src="assets/main/section4-1.png"
                      srcSet="assets/main/section4-1@2x.png 2x,assets/main/section4-1@3x.png 3x "
                      alt="background"
                    />
                  </div>
                </div>
                <div className="content-left" ref={section4Second}>
                  <div className="background-color">
                    <div className="content-top">
                      <div className="title font-title2">에코대체제</div>
                      <div className="sub font-body2">
                        기존 자원을 대체하는 환경 대체제의 개발로 <br />
                        일상의 편리함을 지키며 <br />
                        동시에 환경개선의 양립을 추구합니다
                      </div>
                      <div
                        className="btn"
                        onClick={() => {
                          Navwork(1);
                        }}
                      >
                        <div className="title font-subtitle4">더보기</div>
                        <img src="assets/common/right-arrow.svg" alt="arrow" />
                      </div>
                      <div className="text font-subtitle2">
                        필환경 패키지 개발 <br />
                        필환경 단열건축자재 개발
                      </div>
                    </div>
                  </div>
                  <div className="background">
                    <img
                      src="assets/main/section4-2.png"
                      srcSet="assets/main/section4-2@2x.png 2x,assets/main/section4-2@3x.png 3x "
                      alt="background"
                    />
                  </div>
                </div>
                <div className="content-left" ref={section4sthird}>
                  <div className="background-color">
                    <div className="content-top">
                      <div className="title font-title2">경험설계</div>
                      <div className="sub font-body2">
                        기술과 자재의 개발에서 나아가 <br />
                        사람들의 경험과 행동에서
                        <br /> 환경을 지킬 수 있는 해답을 찾습니다
                      </div>
                      <div
                        className="btn"
                        onClick={() => {
                          Navwork(2);
                        }}
                      >
                        <div className="title font-subtitle4">더보기</div>
                        <img src="assets/common/right-arrow.svg" alt="arrow" />
                      </div>
                      <div className="text font-subtitle2">
                        친환경 건설 단열재
                      </div>
                    </div>
                  </div>
                  <div className="background">
                    <img
                      src="assets/main/section4-3.png"
                      srcSet="assets/main/section4-3@2x.png 2x,assets/main/section4-3@3x.png 3x "
                      alt="background"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section4-tablet">
        <div className="section4-wrapper">
          <div className="title-box">
            <div className="title font-subtitle3">사업분야</div>
            <div className="sub font-head1">
              필환경을 위한 첫걸음,
              <br /> 써모웍스
            </div>
          </div>

          <div className="content-tablet">
            <div className="content-wrapper">
              <div className="content-left" ref={section4four}>
                <div className="background-color">
                  <div className="content-top">
                    <div className="title font-title2">그린디바이스</div>
                    <div className="sub font-body2">
                      기기의 설계와 연구를 통해 자동화된 방식으로
                      <br /> 그렇기 때문에 지속가능한 방식으로
                      <br /> 환경을 개선할 수 있는 습관을 만듭니다
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        Navwork(0);
                      }}
                    >
                      <div className="title font-subtitle4">더보기</div>
                      <img src="assets/common/right-arrow.svg" alt="arrow" />
                    </div>
                    <div className="text font-subtitle2">
                      캔웍스 개발 <br />
                      콜드체인 배송을 위한 풀필먼트 포장재설비
                    </div>
                  </div>
                </div>
                <div className="background">
                  <img
                    src="assets/main/section4-1.png"
                    srcSet="assets/main/section4-1@2x.png 2x,assets/main/section4-1@3x.png 3x "
                    alt="background"
                  />
                </div>
              </div>
              <div className="content-middle" ref={section4five}>
                <div className="background-color">
                  <div className="content-top">
                    <div className="title font-title2">에코대체제</div>
                    <div className="sub font-body2">
                      기존 자원을 대체하는 환경 대체제의 개발로 <br />
                      일상의 편리함을 지키며 <br />
                      동시에 환경개선의 양립을 추구합니다
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        Navwork(1);
                      }}
                    >
                      <div className="title font-subtitle4">더보기</div>
                      <img src="assets/common/right-arrow.svg" alt="arrow" />
                    </div>
                    <div className="text font-subtitle2">
                      필환경 패키지 개발 <br />
                      필환경 단열건축자재 개발
                    </div>
                  </div>
                </div>
                <div className="background">
                  <img
                    src="assets/main/section4-2.png"
                    srcSet="assets/main/section4-2@2x.png 2x,assets/main/section4-2@3x.png 3x "
                    alt="background"
                  />
                </div>
              </div>
              <div className="content-right" ref={section4six}>
                <div className="background-color">
                  <div className="content-top">
                    <div className="title font-title2">경험설계</div>
                    <div className="sub font-body2">
                      기술과 자재의 개발에서 나아가 <br />
                      사람들의 경험과 행동에서
                      <br /> 환경을 지킬 수 있는 해답을 찾습니다
                    </div>
                    <div
                      className="btn"
                      onClick={() => {
                        Navwork(2);
                      }}
                    >
                      <div className="title font-subtitle4">더보기</div>
                      <img src="assets/common/right-arrow.svg" alt="arrow" />
                    </div>
                    <div className="text font-subtitle2">
                      친환경 건설 단열재
                    </div>
                  </div>
                </div>
                <div className="background">
                  <img
                    src="assets/main/section4-3.png"
                    srcSet="assets/main/section4-3@2x.png 2x,assets/main/section4-3@3x.png 3x "
                    alt="background"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection4;
